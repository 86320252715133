@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

$px-theme:			#A4CAC0 !default;
$px-theme2:			#F49E72 !default;

$px-dark:			#0F4061 !default;

$px-white:			#fff !default;
$px-black:			#000 !default;

$px-gray:			rgba($px-theme2, .10) !default;


$px-font-size:		15px !default;
$px-line-height:	1.7 !default;
$px-font:			'Rubik', sans-serif !default;


$px-shadow:			0 .375rem 1.5rem 0 rgba(140,152,164,.125) !default;
$px-shadow-sm:		0 12px 15px rgba(140,152,164,.1) !default;


// Breakpoints
$px-media-xsm:		567px !default;
$px-media-sm:		767px !default;
$px-media-md:		991px !default;
$px-media-lg:		1200px !default;