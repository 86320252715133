.footer {
    border-top: 2px solid $px-dark;
    padding-top: 10px;
    padding-bottom: 10px;
    .nav {
        a {
            font-size: 16px;
            color: #555;
            &:hover {
                color: $px-theme;
            }
            + a {
                margin-left: 18px;
            }
        }
    }
}